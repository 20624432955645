<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-10 col-sm-8 col-md-4 text-center">
        <div class="form-group">
          <h5 v-if="currentTeamWeek">{{ currentTeamWeek.created_at }}</h5>
          <select name="week" id="week" class="form-control" v-model="currentWeek" @change="changeTeamWeek">
            <option value="">{{ $t('selectOption') }}</option>
            <option v-for="(week, ind) in teamWeekList" :key="ind" :value="week.name">{{ week.alias }}</option>
          </select>
        </div>
      </div>
      <div class="col-12">
        <div class="table-responsive">
        <div class="tow text-center" v-if="currentTeamWeek" :class="`formation-${currentTeamWeek.formation}`">
          <img src="../../../assets/images/bg-team-week.jpg" alt="fondo" class="w-100">
          <!-- Torneo y division -->
           <div v-if="tournament" class="wrapper-div-tou tou">
            <router-link :to="{ name: 'TournamentHome', params: { lang: lang, console: console, tournamentSlug: tournament.slug } }" class="not-underline">
              <img :src="tournament.image" :alt="tournament.name" class="img-fluid">
              <h6 class="title border-text-black">{{ tournament.name }}</h6>
            </router-link>
          </div>
          <div v-if="divisionFront" class="wrapper-div-tou div">
            <router-link :to="{ name: 'DivisionHome', params: { lang: lang, console: console, divisionSlug: divisionFront.slug } }" class="not-underline">
              <img :src="divisionFront.image" :alt="divisionFront.name" class="img-fluid">
              <h6 class="title border-text-black">{{ divisionFront.name }}</h6>
            </router-link>
          </div>
          <!-- fin torneo y division -->
          <div class="training">
            <h6 class="title border-text-black">{{ $t('training') }}</h6>
            <h5 class="title border-text-black">{{ currentTeamWeek.training }}</h5>
          </div>
          <div class="console">
            <img v-if="console === 'psn'" src="../../../assets/images/psn-icon.png" alt="Consola">
            <img v-if="console === 'xbox'" src="../../../assets/images/xbox-icon.png" alt="Consola">
            <img v-if="console === 'pc'" src="../../../assets/images/pc-icon.png" alt="Consola">
          </div>
          <!-- ST -->
          <div v-for="(player, ind) in st" :key="`st-${ind}`" class="player st" :class="`st-${ind}`">
            <player-card :player="player" :lang="lang" :console="console"></player-card>
          </div>
          <!-- CAM -->
          <div v-for="(player, ind) in cam" :key="`cam-${ind}`" class="player cam">
            <player-card :player="player" :lang="lang" :console="console"></player-card>
          </div>
          <!-- LM -->
          <div v-for="(player, ind) in lm" :key="`lm-${ind}`" class="player lm">
            <player-card :player="player" :lang="lang" :console="console"></player-card>
          </div>
          <!-- RM -->
          <div v-for="(player, ind) in rm" :key="`rm-${ind}`" class="player rm">
            <player-card :player="player" :lang="lang" :console="console"></player-card>
          </div>
          <!-- CDM -->
          <div v-for="(player, ind) in cdm" :key="`cdm-${ind}`" class="player cdm" :class="`cdm-${ind}`">
            <player-card :player="player" :lang="lang" :console="console"></player-card>
          </div>
          <!-- lb -->
          <div v-for="(player, ind) in lb" :key="`lb-${ind}`" class="player lb">
            <player-card :player="player" :lang="lang" :console="console"></player-card>
          </div>
          <!-- CDM -->
          <div v-for="(player, ind) in rb" :key="`rb-${ind}`" class="player rb">
            <player-card :player="player" :lang="lang" :console="console"></player-card>
          </div>
          <!-- CB -->
          <div v-for="(player, ind) in cb" :key="`cb-${ind}`" class="player cb" :class="`cb-${ind}`">
            <player-card :player="player" :lang="lang" :console="console"></player-card>
          </div>
          <div v-for="(player, ind) in gk" :key="`gk-${ind}`" class="player gk">
            <player-card :player="player" :lang="lang" :console="console"></player-card>
          </div>
        </div>
        </div>
      </div>
      <div class="col-12 substitutes">
        <div class="wrapper">
          <div v-for="(player, ind) in substitutes" :key="`subs-${ind}`" class="player">
            <player-card :player="player" :lang="lang" :console="console"></player-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import PlayerCard from '../partials/PlayerCard'

export default {
  components: {
    PlayerCard
  },
  data () {
    return {
      teamWeekList: [],
      players: [],
      substitutes: [],
      currentWeek: '',
      currentTeamWeek: {},
      tournament: {},
      st: [],
      cam: [],
      lm: [],
      rm: [],
      cdm: [],
      rb: [],
      lb: [],
      cb: [],
      gk: []
    }
  },
  computed: {
    ...mapGetters([
      'lang',
      'console',
      'divisionFront'
    ])
  },
  mounted () {
    this.$store.dispatch('SET_MENU_PRIMARY_DIVISION', 'teamweek')
    this.fetchData()
  },
  watch: {
    '$route'() {
      this.fetchData()
    }
  },
  methods: {
    changeTeamWeek() {
      if (this.currentWeek) {
        this.$router.push({ name: 'DivisionTeamWeek', params: { lang: this.lang, console: this.console, divisionSlug: this.divisionFront.slug, code: this.currentWeek } })
      }
    },
    fetchData () {
      const lang = this.$route.params.lang
      const console = this.$route.params.console
      const slug = this.$route.params.divisionSlug
      const code = this.$route.params.code
      const path = `${lang}/console/${console}/division/${slug}/team-of-the-week/week-${code}`
      this.$axios.get(path).then((response) => {
        const data = response.data
        this.tournament = data.tournament
        this.teamWeekList = data.team_week_list
        this.currentTeamWeek = data.current_team_week
        this.players = data.players
        this.substitutes = data.substitutes
        this.currentWeek = code
        this.st = this.players.filter(player => {
          return player.position === 'ST'
        })
        this.cam = this.players.filter(player => {
          return player.position === 'CAM'
        })
        this.rm = this.players.filter(player => {
          return player.position === 'RM'
        })
        this.lm = this.players.filter(player => {
          return player.position === 'LM'
        })
        this.cdm = this.players.filter(player => {
          return player.position === 'CDM'
        })
        this.lb = this.players.filter(player => {
          return player.position === 'LB'
        })
        this.rb = this.players.filter(player => {
          return player.position === 'RB'
        })
        this.cb = this.players.filter(player => {
          return player.position === 'CB'
        })
        this.gk = this.players.filter(player => {
          return player.position === 'GK'
        })
      })
    }
  }
}
</script>
